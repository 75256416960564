import oss from 'ali-oss'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { Loading } from 'element-ui'
import { getOssRamToken } from '@/js/api/commonApi'

// 阿里上传文档
// https://help.aliyun.com/document_detail/64047.html?spm=a2c4g.11186623.6.1237.6fa426fdzsD6pR

const getFileName = (file) => {
    const _fileType = file.name.substr(file.name.lastIndexOf('.'))
    const _fileName = uuidv4().replace(/-/g, '')
    return `${dayjs().format('YYYYMMDD')}/${_fileName}${_fileType}`
}

export const imageCompressor = (file) => {
    return new Promise((resolve) => {
        try {
            if (file && file.size && file.size > 1024 * 1024 && file.type.indexOf('image') > -1) {
                const _img = new Image()
                _img.src = window.URL.createObjectURL(file)
                _img.onload = function () {
                    try {
                        const _imgEl = this
                        let _w = _imgEl.width
                        let _h = _imgEl.height

                        const _canvas = document.createElement('canvas')
                        _canvas.width = _w
                        _canvas.height = _h
                        const _ctx = _canvas.getContext('2d')
                        _ctx.drawImage(_imgEl, 0, 0, _w, _h)
                        const _base64 = _canvas.toDataURL(file.type, 0.8)

                        // base64 转 file
                        let _arr = _base64.split(',')
                        let _bstr = window.atob(_arr[1])
                        let _n = _bstr.length
                        let _u8arr = new Uint8Array(_n)
                        while (_n--) {
                            _u8arr[_n] = _bstr.charCodeAt(_n)
                        }

                        const _newFile = new File([_u8arr], file.name, { type: file.type })
                        resolve(_newFile)
                    } catch (e) {
                        resolve(file)
                    }
                }
            } else {
                resolve(file)
            }
        } catch (e) {
            resolve(file)
        }
    })
}

// params: bucket, loading, file, success, error, progress, path
// retur: client (client.cancel())
export const UPLOAD_FILE = (params) => {
    let _client = null
    const { bucket = 'res', fileName = '', loading = true, file, success, error, progress, path = '', cancel } = params
    getOssRamToken({ bucketType: bucket.toLocaleUpperCase() }).then((res) => {
        const _ALI_OSS = res.data
        const _host = `https://${_ALI_OSS.customHost}`
        _client = new oss({
            region: _ALI_OSS.endpointName,
            accessKeyId: _ALI_OSS.accessKeyId,
            accessKeySecret: _ALI_OSS.accessKeySecret,
            bucket: _ALI_OSS.bucketName,
            stsToken: _ALI_OSS.securityToken,
        })
        let _loading = null
        if (loading) _loading = Loading.service({ text: '文件上传中', background: 'rgba(0, 0, 0, 0.2)' })
        const _fileName = getFileName(file)
        let reader = new FileReader()
        reader.readAsDataURL(file)

        let _path = `${path}${_fileName}`
        if (fileName) {
            _path = `${path}${fileName}`
        }
        console.log(_path)
        reader.onloadend = () => {
            _client
                .multipartUpload(_path, file, {
                    progress: (p) => {
                        if (progress) progress(parseInt(p * 100))
                    },
                })
                .then((res) => {
                    if (_loading) _loading.close()
                    if (success) {
                        success({
                            name: file.name.replace(/ /g, '_'),
                            url: `${_host}/${res.name}`,
                        })
                    }
                })
                .catch((err) => {
                    // 中断上传
                    if (err.name === 'cancel') {
                        if (cancel) cancel()
                    } else {
                        if (error) error(err)
                    }
                    if (_loading) _loading.close()
                })
        }
    })
    return _client
}
